<template>

    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <left-bar-comp>
            <sports-left-bar-comp></sports-left-bar-comp>
        </left-bar-comp>

        <div class="content_section">
            <div class="content">
                <div class="main_panel">
                    <table class="utable" style="width: 100%;">
                        <tr>
                            <th style="text-align: center; width: 25%;">추천인</th>
                            <th style="text-align: center; width: 25%;">아이디</th>
                            <th style="text-align: center;width: 25%">최종접속시간</th>
                            <th style="text-align: center;width: 25%">가입일</th>
                        </tr>
                        <tr v-for="(item,index) in referrerUsers" :key="index">
                            <td style="" align="center">{{item.nickname}}</td>
                            <td style="" align="center">{{item.username}}</td>
                            <td style="" align="center">{{item.lastLoginTime|datef('MM월 DD일')}}</td>
                            <td style="" align="center">{{item.createTime|datef('MM월 DD일')}}</td>
                        </tr>
                    </table>

                    <!--페이지-->
                    <pagination :page-index="pageNum"
                                :total="total"
                                :page-size="pageSize"
                                @change="pageChange"></pagination>

                </div>

            </div>
        </div>

        <!-- right 메뉴 -->
        <right-bar-comp>
            <!--내정보-->
            <user-info-comp slot="userinfo"></user-info-comp>
            <right-buttons-comp slot="btns"></right-buttons-comp>
            <!--배팅카트-->
            <sports-bet-cart-comp slot="betcart"></sports-bet-cart-comp>
            <right-bar-banner-comp slot="banner"></right-bar-banner-comp>
        </right-bar-comp>

        <foot-comp></foot-comp>
        <mobile-foot-menus></mobile-foot-menus>

    </div>

</template>

<script>
    import RightBarComp from "../../components/RightBarComp";
    import SubTitle from "../../components/SubTitle";
    import {changePasswd, referrerPointLog, referrerUsers} from "../../network/userRequest";
    import LeftBarComp from "../../components/LeftBarComp";
    import LeisureLeftComp from "../../components/leisuregame/LeisureLeftComp";
    import Pagination from "../../components/pagenation/Pagination";
    import FootComp from "../../components/FootComp";
    import TopbarComp from "../../components/TopbarComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import sportsConst from "../../common/sportsConst";
    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../../store/mutation-types";
    import {postionMixin} from "../../common/mixin";
    import UserInfoComp from "../../components/UserInfoComp";
    import RightButtonsComp from "../../components/RightButtonsComp";
    import SportsBetCartComp from "../../components/sports/SportsBetCartComp";
    import MobileFootMenus from "../../components/MobileFootMenus";

    export default {
        name: "UserInfoRef",
        mixins: [postionMixin],
        components: {
            MobileFootMenus,
            SportsBetCartComp,
            RightButtonsComp,
            UserInfoComp,
            SportsLeftBarComp,
            RightBarBannerComp,
            TopbarComp, FootComp, Pagination, LeisureLeftComp, LeftBarComp, SubTitle, RightBarComp
        },
        data() {
            return {
                referrerUsers: [],
                pageNum: 1,
                pageSize: 20,
                total: 1,
                sportsConst,
                position: "추천인정보",
            }
        },
        methods: {
            getRefUserInfo() {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                referrerUsers(this.pageNum, this.pageSize).then(res => {
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                    if (res.data.success) {
                        this.total = res.data.total
                        this.referrerUsers = res.data.data
                    }
                })
            },
            //pagination.vue 에서 페이지 클릭 시 emit발송접수 메소드
            pageChange(page) {
                this.pageNum = page
                this.getRefUserInfo()
            },
        },
        created() {
            this.getRefUserInfo()
        }
    }
</script>

<style scoped>
    @import url("../../assets/css/base.css");
    @import url("../../assets/css/common.css");
    @import url("../../assets/css/style.css");
    @import url("../../assets/css/media.css");
    @import url("../../assets/css/sports.css");

    .user-info {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
        justify-content: center;
        text-align: left;
    }

    .user-info span {
        display: inline-block;
        border-bottom: 1px solid #060607;
        font-size: 14px;
        line-height: 30px;
    }

    .user-info .tk {
        width: 40%;
        background-color: var(--noticeBg);
        text-align: center;
        color: #fff9ff;
    }

    .user-info .t {
        width: 60%;
        background-color: var(--noticeBg2);
        padding-left: 10px;
        box-sizing: border-box;
        color: #ffffff;
    }

    .user-info input {
        background-color: #e0e0e0;
        height: 20px;
        color: #1c1c25;
        padding-left: 6px;
    }

    @media screen and (max-width: 800px) {
        input {
            width: 100%;
        }
    }

</style>